<template>
  <div class="alphabet-en">
    <div class="title">{{$t('alphabet.en.en_alp_content_1')}}</div>
    <div class="body">{{$t('alphabet.en.en_alp_content_2')}}</div>
    <div class="letters">
      <div class="letter" @click="playAudio('a')">A a</div>
      <div class="letter" @click="playAudio('b')">B b</div>
      <div class="letter" @click="playAudio('c')">C c</div>
      <div class="letter" @click="playAudio('d')">D d</div>
      <div class="letter" @click="playAudio('e')">E e</div>
      <div class="letter" @click="playAudio('f')">F f</div>
      <div class="letter" @click="playAudio('g')">G g</div>
      <div class="letter" @click="playAudio('h')">H h</div>
      <div class="letter" @click="playAudio('i')">I i</div>
      <div class="letter" @click="playAudio('j')">J j</div>
      <div class="letter" @click="playAudio('k')">K k</div>
      <div class="letter" @click="playAudio('l')">L l</div>
      <div class="letter" @click="playAudio('m')">M m</div>
      <div class="letter" @click="playAudio('n')">N n</div>
      <div class="letter" @click="playAudio('o')">O o</div>
      <div class="letter" @click="playAudio('p')">P p</div>
      <div class="letter" @click="playAudio('q')">Q q</div>
      <div class="letter" @click="playAudio('r')">R r</div>
      <div class="letter" @click="playAudio('s')">S s</div>
      <div class="letter" @click="playAudio('t')">T t</div>
      <div class="letter" @click="playAudio('u')">U u</div>
      <div class="letter" @click="playAudio('v')">V v</div>
      <div class="letter" @click="playAudio('w')">W w</div>
      <div class="letter" @click="playAudio('x')">X x</div>
      <div class="letter" @click="playAudio('y')">Y y</div>
      <div class="letter" @click="playAudio('z')">Z z</div>
    </div>
    <div class="title">{{$t('alphabet.en.en_alp_content_3')}}</div>
    <div class="body">{{$t('alphabet.en.en_alp_content_4')}}</div>
    <br>
    <div class="body">{{$t('alphabet.en.en_alp_content_5')}}</div>
    <div class="subtitle">{{$t('alphabet.en.en_alp_content_6')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-s thead">{{$t('alphabet.en.en_alp_content_19')}}</div>
        <div class="td td-m thead">{{$t('alphabet.en.en_alp_content_20')}}</div>
        <div class="td td-s thead">{{$t('alphabet.en.en_alp_content_19')}}</div>
        <div class="td td-m thead">{{$t('alphabet.en.en_alp_content_20')}}</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/b/</div>
        <div class="td td-m audio" @click="playAudio('buy')"><span class="highlight">b</span>uy<br>/<span class="highlight">b</span>aɪ/</div>
        <div class="td td-s disabled">/p/</div>
        <div class="td td-m audio" @click="playAudio('pie')"><span class="highlight">p</span>ie<br>/<span class="highlight">p</span>aɪ/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/d/</div>
        <div class="td td-m audio" @click="playAudio('die')"><span class="highlight">d</span>ie<br>/<span class="highlight">d</span>aɪ/</div>
        <div class="td td-s disabled">/t/</div>
        <div class="td td-m audio" @click="playAudio('tie')"><span class="highlight">t</span>ie<br>/<span class="highlight">t</span>aɪ/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/dʒ/</div>
        <div class="td td-m audio" @click="playAudio('giant')"><span class="highlight">g</span>iant<br>*/ˈ<span class="highlight">dʒ</span>aɪənt/</div>
        <div class="td td-s disabled">/tʃ/</div>
        <div class="td td-m audio" @click="playAudio('chill')"><span class="highlight">ch</span>ill<br>/ˈ<span class="highlight">tʃ</span>ɪl/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/v/</div>
        <div class="td td-m audio" @click="playAudio('van')"><span class="highlight">v</span>an<br>/<span class="highlight">v</span>æn/</div>
        <div class="td td-s disabled">/f/</div>
        <div class="td td-m audio" @click="playAudio('fan')"><span class="highlight">f</span>an<br>/<span class="highlight">f</span>æn/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/ɡ/</div>
        <div class="td td-m audio" @click="playAudio('good')"><span class="highlight">g</span>ood<br>/<span class="highlight">g</span>ʊd/</div>
        <div class="td td-s disabled">/k/</div>
        <div class="td td-m audio" @click="playAudio('cook')"><span class="highlight">c</span>oo<span class="highlight">k</span><br>/<span class="highlight">k</span>ʊ<span class="highlight">k</span>/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/z/</div>
        <div class="td td-m audio" @click="playAudio('zoo')"><span class="highlight">z</span>oo<br>/<span class="highlight">z</span>uː/</div>
        <div class="td td-s disabled">/s/</div>
        <div class="td td-m audio" @click="playAudio('sue')"><span class="highlight">s</span>ue<br>/<span class="highlight">s</span>uː/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/ð/</div>
        <div class="td td-m audio" @click="playAudio('though')"><span class="highlight">th</span>ough<br>/<span class="highlight">ð</span>əʊ/</div>
        <div class="td td-s disabled">/θ/</div>
        <div class="td td-m audio" @click="playAudio('throw')"><span class="highlight">th</span>row<br>/<span class="highlight">θ</span>rəʊ/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/r/</div>
        <div class="td td-m audio" @click="playAudio('rye')"><span class="highlight">r</span>ye<br>/<span class="highlight">r</span>aɪ/</div>
        <div class="td td-s disabled">/l/</div>
        <div class="td td-m audio" @click="playAudio('lie')"><span class="highlight">l</span>ie<br>/<span class="highlight">l</span>aɪ/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/ʒ/</div>
        <div class="td td-m audio" @click="playAudio('pleasure')">plea<span class="highlight">s</span>ure<br>/ˈple<span class="highlight">ʒ</span>ər/</div>
        <div class="td td-s disabled">/ʃ/</div>
        <div class="td td-m audio" @click="playAudio('shy')"><span class="highlight">sh</span>y<br>/<span class="highlight">ʃ</span>aɪ/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/h/</div>
        <div class="td td-m audio" @click="playAudio('high')"><span class="highlight">h</span>igh<br>/<span class="highlight">h</span>aɪ/</div>
        <div class="td td-s disabled">/ŋ/</div>
        <div class="td td-m audio" @click="playAudio('singer')">si<span class="highlight">ng</span>er<br>/ˈsɪ<span class="highlight">ŋ</span>ər/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/m/</div>
        <div class="td td-m audio" @click="playAudio('my')"><span class="highlight">m</span>y<br>/<span class="highlight">m</span>aɪ/</div>
        <div class="td td-s disabled">/n/</div>
        <div class="td td-m audio" @click="playAudio('nine')"><span class="highlight">n</span>ine<br>/<span class="highlight">n</span>aɪn/</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">/j/</div>
        <div class="td td-m audio" @click="playAudio('yes')"><span class="highlight">y</span>es<br>/<span class="highlight">j</span>es/</div>
        <div class="td td-s disabled">/w/</div>
        <div class="td td-m audio" @click="playAudio('white')"><span class="highlight">wh</span>ite<br>/<span class="highlight">w</span>aɪt/</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.en.en_alp_content_7')}}</div>
    <div class="subtitle">{{$t('alphabet.en.en_alp_content_8')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-s thead">{{$t('alphabet.en.en_alp_content_19')}}</div>
        <div class="td td-m thead">{{$t('alphabet.en.en_alp_content_20')}}</div>
        <div class="td td-s thead">{{$t('alphabet.en.en_alp_content_19')}}</div>
        <div class="td td-m thead">{{$t('alphabet.en.en_alp_content_20')}}</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('1')">/ɑː/</div>
        <div class="td td-m audio" @click="playAudio('father')">f<span class="highlight">a</span>ther<br>/ˈf<span class="highlight">ɑː</span>ðər/</div>
        <div class="td td-s audio symbel" @click="playAudio('9')">/ʌ/</div>
        <div class="td td-m audio" @click="playAudio('bus')">b<span class="highlight">u</span>s<br>/b<span class="highlight">ʌ</span>s/</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('2')">/e/</div>
        <div class="td td-m audio" @click="playAudio('bed')">b<span class="highlight">e</span>d<br>/b<span class="highlight">e</span>d/</div>
        <div class="td td-s audio symbel" @click="playAudio('10')">/æ/</div>
        <div class="td td-m audio" @click="playAudio('cat')">c<span class="highlight">a</span>t<br>/k<span class="highlight">æ</span>t/</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('3')">/ɜː/</div>
        <div class="td td-m audio" @click="playAudio('her')">h<span class="highlight">e</span>r<br>/h<span class="highlight">ɜː</span>r/</div>
        <div class="td td-s audio symbel" @click="playAudio('11')">/ə/</div>
        <div class="td td-m audio" @click="playAudio('focus')">foc<span class="highlight">u</span>s<br>/ˈfoʊk<span class="highlight">ə</span>s/</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('4')">/iː/</div>
        <div class="td td-m audio" @click="playAudio('see')">s<span class="highlight">ee</span><br>/s<span class="highlight">iː</span>/</div>
        <div class="td td-s audio symbel" @click="playAudio('12')">/ɪ/</div>
        <div class="td td-m audio" @click="playAudio('kid')">k<span class="highlight">i</span>d<br>/k<span class="highlight">ɪ</span>d/</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('5')">/ɔː/</div>
        <div class="td td-m audio" @click="playAudio('thought')">th<span class="highlight">ough</span>t<br>/θ<span class="highlight">ɔː</span>t/</div>
        <div class="td td-s audio symbel" @click="playAudio('13')">/ɒ/</div>
        <div class="td td-m audio" @click="playAudio('watch')">w<span class="highlight">a</span>tch<br>/w<span class="highlight">ɒ</span>tʃ/</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('6')">/uː/</div>
        <div class="td td-m audio" @click="playAudio('food')">f<span class="highlight">oo</span>d<br>/f<span class="highlight">uː</span>d/</div>
        <div class="td td-s audio symbel" @click="playAudio('14')">/ʊ/</div>
        <div class="td td-m audio" @click="playAudio('good')">g<span class="highlight">oo</span>d<br>/ɡ<span class="highlight">ʊ</span>d/</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('7')">/aɪ/</div>
        <div class="td td-m audio" @click="playAudio('pie')">p<span class="highlight">ie</span><br>/p<span class="highlight">aɪ</span>/</div>
        <div class="td td-s audio symbel" @click="playAudio('15')">/eɪ/</div>
        <div class="td td-m audio" @click="playAudio('face')">f<span class="highlight">a</span>ce<br>/f<span class="highlight">eɪ</span>s/</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('8')">/aʊ/</div>
        <div class="td td-m audio" @click="playAudio('mouth')">m<span class="highlight">ou</span>th<br>/m<span class="highlight">aʊ</span>θ/</div>
        <div class="td td-s audio symbel" @click="playAudio('16')">/ɔɪ/</div>
        <div class="td td-m audio" @click="playAudio('boy')">b<span class="highlight">oy</span><br>/b<span class="highlight">ɔɪ</span>/</div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="title">{{$t('alphabet.en.en_alp_content_9')}}</div>
    <div class="title">{{$t('alphabet.en.en_alp_content_10')}}</div>
    <div class="subtitle">{{$t('alphabet.en.en_alp_content_11')}}</div>
    <div class="body">{{$t('alphabet.en.en_alp_content_12')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l thead">{{$t('alphabet.en.en_alp_content_21')}}</div>
        <div class="td td-l thead">{{$t('alphabet.en.en_alp_content_22')}}</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('21')">/ɑː/</div>
        <div class="td td-m audio" @click="playAudio('startuk')">st<span class="highlight">ar</span>t</div>
        <div class="td td-s audio symbel" @click="playAudio('17')">/ɑːr/</div>
        <div class="td td-m audio" @click="playAudio('startus')">st<span class="highlight">ar</span>t</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('22')">/ɔː/</div>
        <div class="td td-m audio" @click="playAudio('northuk')">n<span class="highlight">or</span>th</div>
        <div class="td td-s audio symbel" @click="playAudio('18')">/ɔːr/</div>
        <div class="td td-m audio" @click="playAudio('northus')">n<span class="highlight">or</span>th</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('23')">/ɜː/</div>
        <div class="td td-m audio" @click="playAudio('worduk')">w<span class="highlight">or</span>d</div>
        <div class="td td-s audio symbel" @click="playAudio('19')">/ɜːr/</div>
        <div class="td td-m audio" @click="playAudio('wordus')">w<span class="highlight">or</span>d</div>
      </div>
      <div class="tr">
        <div class="td td-s audio symbel" @click="playAudio('24')">/ə/</div>
        <div class="td td-m audio" @click="playAudio('motheruk')">moth<span class="highlight">er</span></div>
        <div class="td td-s audio symbel" @click="playAudio('20')">/ər/</div>
        <div class="td td-m audio" @click="playAudio('motherus')">moth<span class="highlight">er</span></div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.en.en_alp_content_13')}}</div>
    <div class="body">{{$t('alphabet.en.en_alp_content_14')}}</div>
    <br>
    <div class="body">{{$t('alphabet.en.en_alp_content_15')}}</div>
    <br>
    <div class="body">{{$t('alphabet.en.en_alp_content_16')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l thead">{{$t('alphabet.en.en_alp_content_23')}}</div>
        <div class="td td-l thead">{{$t('alphabet.en.en_alp_content_24')}}</div>
      </div>
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('colour')">col<span class="highlight">our</span></div>
        <div class="td td-l audio" @click="playAudio('color')">col<span class="highlight">or</span></div>
      </div>
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('centre')">cent<span class="highlight">re</span></div>
        <div class="td td-l audio" @click="playAudio('center')">cent<span class="highlight">er</span></div>
      </div>
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('grey')">gr<span class="highlight">e</span>y</div>
        <div class="td td-l audio" @click="playAudio('gray')">gr<span class="highlight">a</span>y</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.en.en_alp_content_17')}}</div>
    <br>
    <div class="body">{{$t('alphabet.en.en_alp_content_18')}}</div>
  </div>
</template>

<script>
  export default {
    methods: {
      playAudio(filename) {
        this.$emit('playAudio', filename)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/courses/alphabet.scss';
</style>