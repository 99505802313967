<template>
  <div class="alphabet-fr" @click="clickAgent">
    <div class="title">{{$t('alphabet.fr.fr_alp_section_1_title')}}</div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_1_content_1')}}</div>
    <div class="letters">
      <div class="letter highlight" @click="playAudio('a')">A a</div>
      <div class="letter" @click="playAudio('b')">B b</div>
      <div class="letter" @click="playAudio('c')">C c</div>
      <div class="letter" @click="playAudio('d')">D d</div>
      <div class="letter highlight" @click="playAudio('e')">E e</div>
      <div class="letter" @click="playAudio('f')">F f</div>
      <div class="letter" @click="playAudio('g')">G g</div>
      <div class="letter" @click="playAudio('h')">H h</div>
      <div class="letter highlight" @click="playAudio('i')">I i</div>
      <div class="letter" @click="playAudio('j')">J j</div>
      <div class="letter" @click="playAudio('k')">K k</div>
      <div class="letter" @click="playAudio('l')">L l</div>
      <div class="letter" @click="playAudio('m')">M m</div>
      <div class="letter" @click="playAudio('n')">N n</div>
      <div class="letter highlight" @click="playAudio('o')">O o</div>
      <div class="letter" @click="playAudio('p')">P p</div>
      <div class="letter" @click="playAudio('q')">Q q</div>
      <div class="letter" @click="playAudio('r')">R r</div>
      <div class="letter" @click="playAudio('s')">S s</div>
      <div class="letter" @click="playAudio('t')">T t</div>
      <div class="letter highlight" @click="playAudio('u')">U u</div>
      <div class="letter" @click="playAudio('v')">V v</div>
      <div class="letter" @click="playAudio('w')">W w</div>
      <div class="letter" @click="playAudio('x')">X x</div>
      <div class="letter highlight" @click="playAudio('y')">Y y</div>
      <div class="letter" @click="playAudio('z')">Z z</div>
    </div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_1_content_2')}}</div>
    <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_2_title')}}</div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_2_content_1')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-full audio" @click="playAudio('professeur')"><span class="highlight">professeur</span></div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_2_content_2').replace('[r]', replaceDoms['r'])"></div>
    <br>
    <div class="body highlight">{{$t('alphabet.fr.fr_alp_section_2_content_3')}}</div>
    <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_3_title')}}</div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_3_content_1')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('e1tudiant')">étu<span class="highlight">diant</span></div>
        <div class="td td-l audio" @click="playAudio('franc1ais')">Fran<span class="highlight">çais</span></div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_3_content_2')}}</div>
    <div class="title">{{$t('alphabet.fr.fr_alp_section_4_title')}}</div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_1').replace('[a]', replaceDoms['a'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('pas')">p<span class="highlight">a</span>s</div>
        <div class="td td-m-l audio" @click="playAudio('pa2le')">p<span class="highlight">â</span>le</div>
        <div class="td td-m-l audio" @click="playAudio('a1')"><span class="highlight">à</span></div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_2').replace('[ə]', replaceDoms['ə'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('le')">l<span class="highlight">e</span></div>
        <div class="td td-l audio" @click="playAudio('premier')">pr<span class="highlight">e</span>mier</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_3').replace('[i]', replaceDoms['i'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('lire')">l<span class="highlight">i</span>re</div>
        <div class="td td-m-l audio" @click="playAudio('i1le')"><span class="highlight">î</span>le</div>
        <div class="td td-m-l audio" @click="playAudio('nai2f')">na<span class="highlight">ï</span>f</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_4').replace('[ɔ]', replaceDoms['ɔ']).replace('[o]', replaceDoms['o'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('porte')">p<span class="highlight">o</span>rte</div>
        <div class="td td-l audio" @click="playAudio('rose')">r<span class="highlight">o</span>se</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_5').replace('[y]', replaceDoms['y'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('sur')">s<span class="highlight">u</span>r</div>
        <div class="td td-l audio" @click="playAudio('su2r')">s<span class="highlight">û</span>r</div>
      </div>
    </div>
    <div class="tips">{{$t('course.tips')}}</div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_6')}}</div>
    <br>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_7')}}</div>
    <br>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_8')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('to1t')">t<span class="highlight">ô</span>t</div>
        <div class="td td-l audio" @click="playAudio('allo1')">all<span class="highlight">ô</span></div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_9')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('stylo')">styl<span class="highlight">o</span></div>
        <div class="td td-l audio" @click="playAudio('me1tro')">métr<span class="highlight">o</span></div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_10')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('chose')">ch<span class="highlight">o</span>se</div>
        <div class="td td-l audio" @click="playAudio('rose')">r<span class="highlight">o</span>se</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_11')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('ordinateur')"><span class="highlight">o</span>rdinateur</div>
        <div class="td td-l audio" @click="playAudio('professeur')">pr<span class="highlight">o</span>fesseur</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_12')}}</div>
    <br>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_13').replace('[e]', replaceDoms['e'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('me1tro')">m<span class="highlight">é</span>tro</div>
        <div class="td td-l audio" @click="playAudio('e1tudiant')"><span class="highlight">é</span>tudiant</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_14').replace('[ɛ]', replaceDoms['ɛ'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('elle')"><span class="highlight">e</span>lle</div>
        <div class="td td-m-l audio" @click="playAudio('cher')">ch<span class="highlight">e</span>r</div>
        <div class="td td-m-l audio" @click="playAudio('mercredi')">m<span class="highlight">e</span>rcredi</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_15').replace('[ɛ]', replaceDoms['ɛ'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('me2re')">m<span class="highlight">è</span>re</div>
        <div class="td td-m-l audio" @click="playAudio('fe3te')">f<span class="highlight">ê</span>te</div>
        <div class="td td-m-l audio" @click="playAudio('noe4l')">No<span class="highlight">ë</span>l</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_16')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('rose')">ros<span class="highlight">e</span></div>
        <div class="td td-m-l audio" @click="playAudio('me2re')">mèr<span class="highlight">e</span></div>
        <div class="td td-m-l audio" @click="playAudio('fe3te')">fêt<span class="highlight">e</span></div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_4_content_17')}}</div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_18').replace('[ɛ]', replaceDoms['ɛ'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-s-l audio" @click="playAudio('vrai')">vr<span class="highlight">ai</span></div>
        <div class="td td-s-l audio" @click="playAudio('tramway')">tramw<span class="highlight">ay</span></div>
        <div class="td td-s-l audio" @click="playAudio('neiger')">n<span class="highlight">ei</span>ger</div>
        <div class="td td-s-l audio" @click="playAudio('trolley')">troll<span class="highlight">ey</span></div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_19').replace('[o]', replaceDoms['o'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('chaud')">ch<span class="highlight">au</span>d</div>
        <div class="td td-l audio" @click="playAudio('eau')"><span class="highlight">eau</span></div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_20').replace('[u]', replaceDoms['u'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('ou')"><span class="highlight">ou</span></div>
        <div class="td td-m-l audio" @click="playAudio('gou2t')">g<span class="highlight">oû</span>t</div>
        <div class="td td-m-l audio" @click="playAudio('aou2t')"><span class="highlight">aoû</span>t</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_21').replace('[œ]', replaceDoms['œ'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('heure')">h<span class="highlight">eu</span>re</div>
        <div class="td td-l audio" @click="playAudio('s%232ur')">s<span class="highlight">œu</span>r</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_22').replace('[ø]', replaceDoms['ø'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('peut')">p<span class="highlight">eu</span>t</div>
        <div class="td td-l audio" @click="playAudio('b%232ufs')">b<span class="highlight">œu</span>fs</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_23').replace('[ø]', replaceDoms['ø']).replace('[ə]', replaceDoms['ə']).replace('[œ]', replaceDoms['œ'])"></div>
    <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_4_content_24')}}</div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_25').replace('[ɛ̃]', replaceDoms['ɛ̃']).replace('[ɑ̃]', replaceDoms['ɑ̃']).replace('[ɔ̃]', replaceDoms['ɔ̃']).replace('[œ̃]', replaceDoms['œ̃'])"></div>
    <br>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_26').replace('[ɑ̃]', replaceDoms['ɑ̃'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('chanter')">ch<span class="highlight">an</span>ter</div>
        <div class="td td-m-l audio" @click="playAudio('lent')">l<span class="highlight">en</span>t</div>
        <div class="td td-m-l audio" @click="playAudio('temps')">t<span class="highlight">em</span>ps</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_28').replace('[ɛ̃]', replaceDoms['ɛ̃'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('vin')">v<span class="highlight">in</span></div>
        <div class="td td-m-l audio" @click="playAudio('simple')">s<span class="highlight">im</span>ple</div>
        <div class="td td-m-l audio" @click="playAudio('syndicat')">s<span class="highlight">yn</span>dicat</div>
      </div>
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('pain')">p<span class="highlight">ain</span></div>
        <div class="td td-m-l audio" @click="playAudio('faim')">f<span class="highlight">aim</span></div>
        <div class="td td-m-l audio" @click="playAudio('plein')">pl<span class="highlight">ein</span></div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_29').replace('[ɔ̃]', replaceDoms['ɔ̃'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('bon')">b<span class="highlight">on</span></div>
        <div class="td td-l audio" @click="playAudio('nom')">n<span class="highlight">om</span></div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_30').replace('[œ̃]', replaceDoms['œ̃'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('brun')">br<span class="highlight">un</span></div>
        <div class="td td-l audio" @click="playAudio('lundi')">l<span class="highlight">un</span>di</div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_4_content_31')}}</div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_32')}}</div>
    <br>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_33').replace('[w]', replaceDoms['w'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('oui')"><span class="highlight">ou</span>i</div>
        <div class="td td-l audio" @click="playAudio('jouer')">j<span class="highlight">ou</span>er</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_34').replace('[wa]', replaceDoms['wa'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('moi')">m<span class="highlight">oi</span></div>
        <div class="td td-l audio" @click="playAudio('moyen')">m<span class="highlight">oy</span>en</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_35').replace('[j]', replaceDoms['j'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-full audio" @click="playAudio('yaourt')"><span class="highlight">y</span>aourt</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_36')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('voyelle')">vo<span class="highlight">y</span>elle</div>
        <div class="td td-l audio" @click="playAudio('moyen')">mo<span class="highlight">y</span>en</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_37').replace('[wa]', replaceDoms['wa'])"></div>
    <br>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_38').replace('[j]', replaceDoms['j'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('ciel')">c<span class="highlight">i</span>el</div>
        <div class="td td-m-l audio" @click="playAudio('plier')">pl<span class="highlight">i</span>er</div>
        <div class="td td-m-l audio" @click="playAudio('crier')">cr<span class="highlight">i</span>er</div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_39').replace('[j]', replaceDoms['j'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('travail')">trava<span class="highlight">il</span></div>
        <div class="td td-l audio" @click="playAudio('bataille')">bata<span class="highlight">ille</span></div>
      </div>
    </div>
    <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_40').replace('[ɥ]', replaceDoms['ɥ'])"></div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('huit')">h<span class="highlight">u</span>it</div>
        <div class="td td-l audio" @click="playAudio('nuage')">n<span class="highlight">u</span>age</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_41')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l disabled">ien, yen</div>
        <div class="td td-m-l disabled">[jɛ̃]</div>
        <div class="td td-m-l audio" @click="playAudio('bien')">b<span class="highlight">ien</span></div>
      </div>
      <div class="tr">
        <div class="td td-m-l disabled">oin</div>
        <div class="td td-m-l disabled">[wɛ̃]</div>
        <div class="td td-m-l audio" @click="playAudio('point')">p<span class="highlight">oin</span>t</div>
      </div>
      <div class="tr">
        <div class="td td-m-l disabled">ion, yon</div>
        <div class="td td-m-l disabled">[jɔ̃]</div>
        <div class="td td-m-l audio" @click="playAudio('lyon')">L<span class="highlight">yon</span></div>
      </div>
    </div>
    <div class="title">{{$t('alphabet.fr.fr_alp_section_4_content_42')}}</div>
    <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_4_content_43')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-s-l disabled">B/b</div>
        <div class="td td-s-l audio" @click="playAudio('blanc')"><span class="highlight">b</span>lanc</div>
        <div class="td td-s-l disabled">N/n</div>
        <div class="td td-s-l audio" @click="playAudio('ne')"><span class="highlight">n</span>e</div>
      </div>
      <div class="tr">
        <div class="td td-s-l disabled">C/c</div>
        <div class="td td-s-l audio" @click="playAudio('coq')"><span class="highlight">c</span>oq</div>
        <div class="td td-s-l disabled">P/p</div>
        <div class="td td-s-l audio" @click="playAudio('pomme')"><span class="highlight">p</span>omme</div>
      </div>
      <div class="tr">
        <div class="td td-s-l disabled">D/d</div>
        <div class="td td-s-l audio" @click="playAudio('deux')"><span class="highlight">d</span>eux</div>
        <div class="td td-s-l disabled">R/r</div>
        <div class="td td-s-l audio" @click="playAudio('reims')"><span class="highlight">R</span>eims</div>
      </div>
      <div class="tr">
        <div class="td td-s-l disabled">F/f</div>
        <div class="td td-s-l audio" @click="playAudio('fixer')"><span class="highlight">f</span>ixer</div>
        <div class="td td-s-l disabled">S/s</div>
        <div class="td td-s-l audio" @click="playAudio('simple')"><span class="highlight">s</span>imple</div>
      </div>
      <div class="tr">
        <div class="td td-s-l disabled">G/g</div>
        <div class="td td-s-l audio" @click="playAudio('garc1on')"><span class="highlight">g</span>arçon</div>
        <div class="td td-s-l disabled">T/t</div>
        <div class="td td-s-l audio" @click="playAudio('temps')"><span class="highlight">t</span>emps</div>
      </div>
      <div class="tr">
        <div class="td td-s-l disabled">J/j</div>
        <div class="td td-s-l audio" @click="playAudio('je')"><span class="highlight">j</span>e</div>
        <div class="td td-s-l disabled">V/v</div>
        <div class="td td-s-l audio" @click="playAudio('valise')"><span class="highlight">v</span>alise</div>
      </div>
      <div class="tr">
        <div class="td td-s-l disabled">K/k</div>
        <div class="td td-s-l audio" @click="playAudio('kilo')"><span class="highlight">k</span>ilo</div>
        <div class="td td-s-l disabled">W/w</div>
        <div class="td td-s-l audio" @click="playAudio('week-end')"><span class="highlight">w</span>eek-end</div>
      </div>
      <div class="tr">
        <div class="td td-s-l disabled">L/l</div>
        <div class="td td-s-l audio" @click="playAudio('lent')"><span class="highlight">l</span>ent</div>
        <div class="td td-s-l disabled">Z/z</div>
        <div class="td td-s-l audio" @click="playAudio('ze1ro')"><span class="highlight">z</span>éro</div>
      </div>
      <div class="tr">
        <div class="td td-s-l disabled">M/m</div>
        <div class="td td-s-l audio" @click="playAudio('me2re')"><span class="highlight">m</span>ère</div>
        <div class="td td-s-l"></div>
        <div class="td td-s-l"></div>
      </div>
      <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_4_content_44')}}</div>
      <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_45').replace('[k]', replaceDoms['k']).replace('[s]', replaceDoms['s'])"></div>
      <div class="table">
        <div class="tr">
          <div class="td td-m-l audio" @click="playAudio('ce')"><span class="highlight">c</span>e</div>
          <div class="td td-m-l audio" @click="playAudio('ciel')"><span class="highlight">c</span>iel</div>
          <div class="td td-m-l audio" @click="playAudio('garc1on')">gar<span class="highlight">ç</span>on</div>
        </div>
      </div>
      <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_46').replace('[g]', replaceDoms['g']).replace('[ʒ]', replaceDoms['ʒ'])"></div>
      <div class="table">
        <div class="tr">
          <div class="td td-l audio" @click="playAudio('manger')">man<span class="highlight">g</span>er</div>
          <div class="td td-l audio" @click="playAudio('gentil')"><span class="highlight">g</span>entil</div>
        </div>
      </div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_47')}}</div>
      <div class="table">
        <div class="tr">
          <div class="td td-l audio" @click="playAudio('huit')"><span class="highlight">h</span>uit</div>
          <div class="td td-l audio" @click="playAudio('the1')">t<span class="highlight">h</span>é</div>
        </div>
      </div>
      <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_48').replace('[z]', replaceDoms['z'])"></div>
      <div class="table">
        <div class="tr">
          <div class="td td-l audio" @click="playAudio('rose')">ro<span class="highlight">s</span>e</div>
          <div class="td td-l audio" @click="playAudio('te1le1vision')">télévi<span class="highlight">s</span>ion</div>
        </div>
      </div>
      <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_49').replace('[t]', replaceDoms['t']).replace('[s]', replaceDoms['s'])"></div>
      <div class="table">
        <div class="tr">
          <div class="td td-l disabled">{{$t("alphabet.fr.fr_alp_table_content_1")}}</div>
          <div class="td td-l audio" @click="playAudio('nation')">na<span class="highlight">t</span>ion</div>
        </div>
      </div>
      <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_50').replace('[ks]', replaceDoms['ks']).replace('[s]', replaceDoms['s'])"></div>
      <div class="table">
        <div class="tr">
          <div class="td td-l disabled">{{$t("alphabet.fr.fr_alp_table_content_2")}}</div>
          <div class="td td-s-l audio" @click="playAudio('deux')">deu<span class="highlight">x</span></div>
          <div class="td td-s-l audio" @click="playAudio('prix')">pri<span class="highlight">x</span></div>
        </div>
        <div class="tr">
          <div class="td td-l disabled">{{$t("alphabet.fr.fr_alp_table_content_3")}}</div>
          <div class="td td-s-l audio" @click="playAudio('index')">inde<span class="highlight">x</span></div>
          <div class="td td-s-l audio" @click="playAudio('examen')">e<span class="highlight">x</span>amen</div>
        </div>
        <div class="tr">
          <div class="td td-l disabled">{{$t("alphabet.fr.fr_alp_table_content_4")}}</div>
          <div class="td td-s-l audio" @click="playAudio('six')">si<span class="highlight">x</span></div>
          <div class="td td-s-l audio" @click="playAudio('dix')">di<span class="highlight">x</span></div>
        </div>
      </div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_51')}}</div>
      <br>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_52')}}</div>
      <br>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_53')}}</div>
      <div class="table">
        <div class="tr">
          <div class="td td-l disabled">{{$t("alphabet.fr.fr_alp_table_content_5")}}</div>
          <div class="td td-s-l audio" @click="playAudio('franc1ais')">françai<span class="highlight">s</span></div>
          <div class="td td-s-l audio" @click="playAudio('chaud')">chau<span class="highlight">d</span></div>
        </div>
        <div class="tr">
          <div class="td td-l disabled">{{$t("alphabet.fr.fr_alp_table_content_6")}}</div>
          <div class="td td-s-l audio" @click="playAudio('franc1aise')">françai<span class="highlight">s</span>e</div>
          <div class="td td-s-l audio" @click="playAudio('chaude')">chau<span class="highlight">d</span>e</div>
        </div>
      </div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_54')}}</div>
      <div class="table">
        <div class="tr">
          <div class="td td-m-l audio" @click="playAudio('sac')">sa<span class="highlight">c</span></div>
          <div class="td td-m-l audio" @click="playAudio('neuf')">neu<span class="highlight">f</span></div>
          <div class="td td-m-l audio" @click="playAudio('il')">i<span class="highlight">l</span></div>
        </div>
        <div class="tr">
          <div class="td td-m-l audio" @click="playAudio('coq')">co<span class="highlight">q</span></div>
          <div class="td td-m-l audio" @click="playAudio('cher')">che<span class="highlight">r</span></div>
          <div class="td td-m-l audio" @click="playAudio('exact')">exa<span class="highlight">ct</span></div>
        </div>
      </div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_55')}}</div>
      <div class="table">
        <div class="tr">
          <div class="td td-l audio" @click="playAudio('stop')">sto<span class="highlight">p</span></div>
          <div class="td td-l audio" @click="playAudio('club')">clu<span class="highlight">b</span></div>
        </div>
      </div>
      <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_4_content_56')}}</div>
      <div class="table">
        <div class="tr">
          <div class="td td-m-l disabled">ph</div>
          <div class="td td-m-l disabled">[f]</div>
          <div class="td td-m-l audio" @click="playAudio('photo')"><span class="highlight">ph</span>oto</div>
        </div>
        <div class="tr">
          <div class="td td-m-l disabled">th</div>
          <div class="td td-m-l disabled">[t]</div>
          <div class="td td-m-l audio" @click="playAudio('the1')"><span class="highlight">th</span>é</div>
        </div>
        <div class="tr">
          <div class="td td-m-l disabled">gu</div>
          <div class="td td-m-l disabled">[g]</div>
          <div class="td td-m-l audio" @click="playAudio('guerre')"><span class="highlight">gu</span>erre</div>
        </div>
        <div class="tr">
          <div class="td td-m-l disabled">qu</div>
          <div class="td td-m-l disabled">[k]</div>
          <div class="td td-m-l audio" @click="playAudio('quel')"><span class="highlight">qu</span>el</div>
        </div>
        <div class="tr">
          <div class="td td-m-l disabled">{{$t("alphabet.fr.fr_alp_table_content_7")}}</div>
          <div class="td td-m-l disabled">[ɛks]</div>
          <div class="td td-m-l audio" @click="playAudio('express')"><span class="highlight">ex</span>press</div>
        </div>
        <div class="tr">
          <div class="td td-m-l disabled">{{$t("alphabet.fr.fr_alp_table_content_8")}}</div>
          <div class="td td-m-l disabled">[ɛgz]</div>
          <div class="td td-m-l audio" @click="playAudio('examen')"><span class="highlight">ex</span>amen</div>
        </div>
        <div class="tr">
          <div class="td td-m-l disabled">cc + e/i</div>
          <div class="td td-m-l disabled">[ks]</div>
          <div class="td td-m-l audio" @click="playAudio('accent')">a<span class="highlight">cc</span>ent</div>
        </div>
        <div class="tr">
          <div class="td td-m-l disabled">sc + e/i</div>
          <div class="td td-m-l disabled">[s]</div>
          <div class="td td-m-l audio" @click="playAudio('sce2ne')"><span class="highlight">sc</span>ène</div>
        </div>
        <div class="tr">
          <div class="td td-m-l disabled">ch/sh/sch</div>
          <div class="td td-m-l disabled">[∫]</div>
          <div class="td td-m-l audio" @click="playAudio('cher')"><span class="highlight">ch</span>er</div>
        </div>
        <div class="tr">
          <div class="td td-m-l disabled">mn</div>
          <div class="td td-m-l disabled">[n]</div>
          <div class="td td-m-l audio" @click="playAudio('automne')">auto<span class="highlight">mn</span>e</div>
        </div>
        <div class="tr">
          <div class="td td-m-l disabled">gn</div>
          <div class="td td-m-l disabled">[ɲ]</div>
          <div class="td td-m-l audio" @click="playAudio('ligne')">li<span class="highlight">gn</span>e</div>
        </div>
      </div>
      <div class="title">{{$t('alphabet.fr.fr_alp_section_4_content_59')}}</div>
      <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_4_content_60')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_61')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_62')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_63')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_64')}}</div>
      <div class="table">
        <div class="tr">
          <div class="td td-s-l thead">{{$t("alphabet.fr.fr_alp_table_content_9")}}</div>
          <div class="td td-s-l thead">{{$t("alphabet.fr.fr_alp_table_content_10")}}</div>
          <div class="td td-s thead">{{$t("alphabet.fr.fr_alp_table_content_11")}}</div>
          <div class="td td-m thead">{{$t("alphabet.fr.fr_alp_table_content_12")}}</div>
        </div>
        <div class="tr">
          <div class="td td-s-l disabled">accent aigu</div>
          <div class="td td-s-l disabled">{{$t("alphabet.fr.fr_alp_table_content_13")}}</div>
          <div class="td td-s disabled">é</div>
          <div class="td td-m disabled">{{$t("alphabet.fr.fr_alp_table_content_14")}}</div>
        </div>
        <div class="tr">
          <div class="td td-s-l disabled">accent grave</div>
          <div class="td td-s-l disabled">{{$t("alphabet.fr.fr_alp_table_content_15")}}</div>
          <div class="td td-s disabled">è</div>
          <div class="td td-m disabled">{{$t("alphabet.fr.fr_alp_table_content_16")}}</div>
        </div>
        <div class="tr">
          <div class="td td-s-l disabled">accent circonflexe</div>
          <div class="td td-s-l disabled">{{$t("alphabet.fr.fr_alp_table_content_17")}}</div>
          <div class="td td-s disabled">ê</div>
          <div class="td td-m disabled">{{$t("alphabet.fr.fr_alp_table_content_18")}}</div>
        </div>
        <div class="tr">
          <div class="td td-s-l disabled">tréma</div>
          <div class="td td-s-l disabled">{{$t("alphabet.fr.fr_alp_table_content_19")}}</div>
          <div class="td td-s disabled">ë</div>
          <div class="td td-m disabled">{{$t("alphabet.fr.fr_alp_table_content_20")}}</div>
        </div>
        <div class="tr">
          <div class="td td-s-l disabled">crédille</div>
          <div class="td td-s-l disabled">{{$t("alphabet.fr.fr_alp_table_content_21")}}</div>
          <div class="td td-s disabled">ç</div>
          <div class="td td-m disabled">{{$t("alphabet.fr.fr_alp_table_content_22")}}</div>
        </div>
      </div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_65')}}</div>
      <div class="table">
        <div class="tr">
          <div class="td td-l disabled">A/a</div>
          <div class="td td-l disabled">à, â, æ</div>
        </div>
        <div class="tr">
          <div class="td td-l disabled">E/e</div>
          <div class="td td-l disabled">é, è, ê, ë</div>
        </div>
        <div class="tr">
          <div class="td td-l disabled">I/i</div>
          <div class="td td-l disabled">î, ï</div>
        </div>
        <div class="tr">
          <div class="td td-l disabled">O/o</div>
          <div class="td td-l disabled">ô, œ</div>
        </div>
        <div class="tr">
          <div class="td td-l disabled">U/u</div>
          <div class="td td-l disabled">ù, û, ü</div>
        </div>
        <div class="tr">
          <div class="td td-l disabled">Y/y</div>
          <div class="td td-l disabled">ÿ</div>
        </div>
        <div class="tr">
          <div class="td td-l disabled">C/c</div>
          <div class="td td-l disabled">ç</div>
        </div>
      </div>
      <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_4_content_66')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_67')}}</div>
      <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_4_content_68')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_69')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_70')}}</div>
      <div class="table">
        <div class="tr">
          <div class="td td-full audio highlight" @click="playAudio('5')">C'est un garçon. [sɛ-tœ̃-garsɔ̃]</div>
        </div>
      </div>
      <div class="body" v-html="$t('alphabet.fr.fr_alp_section_4_content_72').replace('[z]', replaceDoms['z'])"></div>
      <div class="table">
        <div class="tr">
          <div class="td td-full audio highlight" @click="playAudio('6')">les enfants [lezɑ̃fɑ̃]</div>
        </div>
      </div>
      <div class="tips">{{$t('course.tips')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_74')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_75')}}</div>
      <div class="subtitle">{{$t('alphabet.fr.fr_alp_section_4_content_76')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_77')}}</div>
      <div class="body">{{$t('alphabet.fr.fr_alp_section_4_content_78')}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        replaceDoms: {
          'œ': '<span class="highlight audioSymbol" id="%232">[œ]</span>',
          'ɛ': '<span class="highlight audioSymbol" id="%233">[ɛ]</span>',
          'ɑ': '<span class="highlight audioSymbol" id="%234">[ɑ]</span>',
          'ɔ': '<span class="highlight audioSymbol" id="%235">[ɔ]</span>',
          'ø': '<span class="highlight audioSymbol" id="%236">[ø]</span>',
          'ə': '<span class="highlight audioSymbol" id="%237">[ə]</span>',
          'ɛ̃': '<span class="highlight audioSymbol" id="%238">[ɛ̃]</span>',
          'ɑ̃': '<span class="highlight audioSymbol" id="%239">[ɑ̃]</span>',
          'ɔ̃': '<span class="highlight audioSymbol" id="%2310">[ɔ̃]</span>',
          'ɥ': '<span class="highlight audioSymbol" id="%2311">[ɥ]</span>',
          '∫': '<span class="highlight audioSymbol" id="%2312">[∫]</span>',
          'ʒ': '<span class="highlight audioSymbol" id="%2313">[ʒ]</span>',
          'ɲ': '<span class="highlight audioSymbol" id="%2314">[ɲ]</span>',
          'œ̃': '<span class="highlight audioSymbol" id="%2315">[œ̃]</span>',
          'i': '<span class="highlight audioSymbol" id="%2316">[i]</span>',
          'y': '<span class="highlight audioSymbol" id="%2317">[y]</span>',
          'e': '<span class="highlight audioSymbol" id="%2318">[e]</span>',
          'a': '<span class="highlight audioSymbol" id="%2319">[a]</span>',
          'u': '<span class="highlight audioSymbol" id="%2320">[u]</span>',
          'o': '<span class="highlight audioSymbol" id="%2321">[o]</span>',
          'j': '<span class="highlight audioSymbol" id="%2322">[j]</span>',
          'ij': '<span class="highlight audioSymbol" id="%2323">[ij]</span>',
          'w': '<span class="highlight audioSymbol" id="%2324">[w]</span>',
          'wa': '<span class="highlight audioSymbol" id="%2325">[wa]</span>',
          'b': '<span class="highlight audioSymbol" id="%2326">[b]</span>',
          'p': '<span class="highlight audioSymbol" id="%2327">[p]</span>',
          'd': '<span class="highlight audioSymbol" id="%2328">[d]</span>',
          't': '<span class="highlight audioSymbol" id="%2329">[t]</span>',
          'g': '<span class="highlight audioSymbol" id="%2330">[g]</span>',
          'gz': '<span class="highlight audioSymbol" id="%2331">[gz]</span>',
          'k': '<span class="highlight audioSymbol" id="%2332">[k]</span>',
          'ks': '<span class="highlight audioSymbol" id="%2333">[ks]</span>',
          'z': '<span class="highlight audioSymbol" id="%2334">[z]</span>',
          's': '<span class="highlight audioSymbol" id="%2335">[s]</span>',
          'v': '<span class="highlight audioSymbol" id="%2336">[v]</span>',
          'f': '<span class="highlight audioSymbol" id="%2337">[f]</span>',
          'n': '<span class="highlight audioSymbol" id="%2338">[n]</span>',
          'm': '<span class="highlight audioSymbol" id="%2339">[m]</span>',
          'r': '<span class="highlight audioSymbol" id="%2340">[r]</span>',
          'l': '<span class="highlight audioSymbol" id="%2341">[l]</span>',
        }
      }
    },
    methods: {
      clickAgent(e) {
        if (e.target.classList.contains('audioSymbol')) {
          this.playAudio(e.target.id)
        }
      },
      playAudio(filename) {
        this.$emit('playAudio', filename)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/courses/alphabet.scss';
  ::v-deep .highlight {
    color: #409EFF;
  }
  ::v-deep .audioSymbol {
    cursor: pointer;
  }
  .tips {
    position: relative;
    display: inline-block;
    background: #ffd25f;
    margin: 16px 0;
    padding: 0 20px 0 10px;
    height: 26px;
    line-height: 26px;
    box-sizing: border-box;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      height: 0;
      border-right: 10px solid #FFFFFF;
      border-left: 10px solid transparent;
      border-bottom: 13px solid transparent;
      border-top: 13px solid transparent;
    }
  }
</style>