<template>
  <div class="alphabet">
    <home-header></home-header>
    <div class="contentWrap">
      <div class="alphabetWrap">
        <div class="header"><i class="backIcon el-icon-arrow-left" @click="goBack"></i></div>
        <div class="content">
          <component :is="learnLanguage" @playAudio="playAudio"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import Header from '@/components/Header.vue'
  import Audio from '@/utils/audioUtil'
  import constants from '@/utils/constants'
  import learnTimeMixins from '@/mixins/learnTimeMixins'
  import { updateHistoryData } from '@/utils/dataUpdateUtil'

  import en from '@/components/courses/alphabet/en.vue'
  import es from '@/components/courses/alphabet/es.vue'
  import fr from '@/components/courses/alphabet/fr.vue'
  
  export default {
    mixins: [ learnTimeMixins ],
    data() {
      return {
        audio: null
      }
    },
    components: {
      'home-header': Header,
      en,
      es,
      fr
    },
    computed: {
      ...Vuex.mapGetters(['learnLanguage'])
    },
    created() {
      this.timingStart()
    },
    beforeDestroy() {
      this.timingStop()
      updateHistoryData(this.duration, 0)
    },
    methods: {
      playAudio(filename) {
        let langParam = constants.langsData[this.learnLanguage]['courseMediaName']
        let audioUrl = constants.alphabetSourceUrl.replaceAll('LANG', langParam).replace('FILENAME', filename)
        this.audio = new Audio([{ audioUrl }])
        this.audio.play()
      },
      goBack() {
        window.history.back()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .alphabet {
    background: #F3F4F8;
    min-height: 100vh;
    .contentWrap {
      display: flex;
      justify-content: center;
      margin: 80px auto 0;
      padding: 14px 0;
      width: 1000px;
      .alphabetWrap {
        box-sizing: border-box;
        width: 680px;
        background: #FFFFFF;
        border-radius: 12px;
        .header {
          padding: 14px 20px;
          border-bottom: 1px solid #C4C9D8;
          .backIcon {
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
          }
        }
        .content {
          padding: 20px 30px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .alphabet {
      .contentWrap {
        width: 100%;
        .alphabetWrap {
          width: 100%;
          border-radius: 0;
        }
      }
    }
  }
</style>