<template>
  <div class="alphabet-es">
    <div class="title">{{$t('alphabet.es.es_alp_content_1')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_2')}}</div>
    <div class="letters">
      <div class="letter" @click="playAudio('a')">A a</div>
      <div class="letter" @click="playAudio('b')">B b</div>
      <div class="letter" @click="playAudio('c')">C c</div>
      <div class="letter highlight" @click="playAudio('ch')">CH ch</div>
      <div class="letter" @click="playAudio('d')">D d</div>
      <div class="letter" @click="playAudio('e')">E e</div>
      <div class="letter" @click="playAudio('f')">F f</div>
      <div class="letter" @click="playAudio('g')">G g</div>
      <div class="letter" @click="playAudio('h')">H h</div>
      <div class="letter" @click="playAudio('i')">I i</div>
      <div class="letter" @click="playAudio('j')">J j</div>
      <div class="letter" @click="playAudio('k')">K k</div>
      <div class="letter" @click="playAudio('l')">L l</div>
      <div class="letter highlight" @click="playAudio('ll')">LL ll</div>
      <div class="letter" @click="playAudio('m')">M m</div>
      <div class="letter" @click="playAudio('n')">N n</div>
      <div class="letter highlight" @click="playAudio('n1')">Ñ ñ</div>
      <div class="letter" @click="playAudio('o')">O o</div>
      <div class="letter" @click="playAudio('p')">P p</div>
      <div class="letter" @click="playAudio('q')">Q q</div>
      <div class="letter" @click="playAudio('r')">R r</div>
      <div class="letter" @click="playAudio('s')">S s</div>
      <div class="letter" @click="playAudio('t')">T t</div>
      <div class="letter" @click="playAudio('u')">U u</div>
      <div class="letter" @click="playAudio('v')">V v</div>
      <div class="letter" @click="playAudio('w')">W w</div>
      <div class="letter" @click="playAudio('x')">X x</div>
      <div class="letter" @click="playAudio('y')">Y y</div>
      <div class="letter" @click="playAudio('z')">Z z</div>
    </div>
    <div class="title">{{$t('alphabet.es.es_alp_content_3')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_4')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-s disabled">D</div>
        <div class="td td-m audio" @click="playAudio('dos')"><span class="highlight">d</span>os</div>
        <div class="td td-s disabled">N</div>
        <div class="td td-m audio" @click="playAudio('nada')"><span class="highlight">n</span>ada</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">F</div>
        <div class="td td-m audio" @click="playAudio('favor')"><span class="highlight">f</span>avor</div>
        <div class="td td-s disabled">P</div>
        <div class="td td-m audio" @click="playAudio('padre')"><span class="highlight">p</span>adre</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">J</div>
        <div class="td td-m audio" @click="playAudio('rojo')">ro<span class="highlight">j</span>o</div>
        <div class="td td-s disabled">S</div>
        <div class="td td-m audio" @click="playAudio('segundo')"><span class="highlight">s</span>egundo</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">K</div>
        <div class="td td-m audio" @click="playAudio('kilo')"><span class="highlight">k</span>ilo</div>
        <div class="td td-s disabled">T</div>
        <div class="td td-m audio" @click="playAudio('tarta')"><span class="highlight">t</span>ar<span class="highlight">t</span>a</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">L</div>
        <div class="td td-m audio" @click="playAudio('leche')"><span class="highlight">l</span>eche</div>
        <div class="td td-s disabled">W</div>
        <div class="td td-m audio" @click="playAudio('kiwi')">ki<span class="highlight">w</span>i</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">M</div>
        <div class="td td-m audio" @click="playAudio('mesa')"><span class="highlight">m</span>esa</div>
        <div class="td td-s disabled">Y</div>
        <div class="td td-m audio" @click="playAudio('yo')"><span class="highlight">y</span>o</div>
      </div>
    </div>
    <div class="title">{{$t('alphabet.es.es_alp_content_5')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_6')}}</div>
    <div class="title">{{$t('alphabet.es.es_alp_content_7')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_8')}}</div>
    <br>
    <div class="body">{{$t('alphabet.es.es_alp_content_9')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-s disabled">ai</div>
        <div class="td td-m audio" @click="playAudio('bailar')">b<span class="highlight">ai</span>lar</div>
        <div class="td td-s disabled">ia</div>
        <div class="td td-m audio" @click="playAudio('piano')">p<span class="highlight">ia</span>no</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">ei</div>
        <div class="td td-m audio" @click="playAudio('seis')">s<span class="highlight">ei</span>s</div>
        <div class="td td-s disabled">ie</div>
        <div class="td td-m audio" @click="playAudio('siete')">s<span class="highlight">ie</span>te</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">oi</div>
        <div class="td td-m audio" @click="playAudio('sois')">s<span class="highlight">oi</span>s</div>
        <div class="td td-s disabled">io</div>
        <div class="td td-m audio" @click="playAudio('armario')">armar<span class="highlight">io</span></div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">oi</div>
        <div class="td td-m audio" @click="playAudio('hoy')">h<span class="highlight">oy</span></div>
        <div class="td td-s disabled">ui</div>
        <div class="td td-m audio" @click="playAudio('muy')">m<span class="highlight">uy</span></div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">iu</div>
        <div class="td td-m audio" @click="playAudio('ciudad')">c<span class="highlight">iu</span>dad</div>
        <div class="td td-s disabled">au</div>
        <div class="td td-m audio" @click="playAudio('autobu1s')"><span class="highlight">au</span>tobús</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">ua</div>
        <div class="td td-m audio" @click="playAudio('agua')">ag<span class="highlight">ua</span></div>
        <div class="td td-s disabled">eu</div>
        <div class="td td-m audio" @click="playAudio('euro')"><span class="highlight">eu</span>ro</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">ue</div>
        <div class="td td-m audio" @click="playAudio('bueno')">b<span class="highlight">ue</span>no</div>
        <div class="td td-s disabled">ou</div>
        <div class="td td-m audio" @click="playAudio('bou')">b<span class="highlight">ou</span></div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">uo</div>
        <div class="td td-m audio" @click="playAudio('antiguo')">antig<span class="highlight">uo</span></div>
        <div class="td td-s"></div>
        <div class="td td-m"></div>
      </div>
    </div>
    <div class="title">{{$t('alphabet.es.es_alp_content_10')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-m disabled">iai</div>
        <div class="td td-xl audio" @click="playAudio('estudia1is')">estud<span class="highlight">iái</span>s</div>
      </div>
      <div class="tr">
        <div class="td td-m disabled">uai/uay</div>
        <div class="td td-xl audio" @click="playAudio('uruguay')">Urug<span class="highlight">uay</span></div>
      </div>
      <div class="tr">
        <div class="td td-m disabled">iei</div>
        <div class="td td-xl audio" @click="playAudio('cambie1is')">camb<span class="highlight">iéi</span>s</div>
      </div>
      <div class="tr">
        <div class="td td-m disabled">uei/üei</div>
        <div class="td td-xl audio" @click="playAudio('averigv1e1is')">averig<span class="highlight">üéi</span>s</div>
      </div>
    </div>
    <div class="title">{{$t('alphabet.es.es_alp_content_11')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_12')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-s disabled">pl</div>
        <div class="td td-m audio" @click="playAudio('plato')"><span class="highlight">pl</span>ato</div>
        <div class="td td-s disabled">pr</div>
        <div class="td td-m audio" @click="playAudio('profesor')"><span class="highlight">pr</span>ofesor</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">bl</div>
        <div class="td td-m audio" @click="playAudio('blanco')"><span class="highlight">bl</span>anco</div>
        <div class="td td-s disabled">br</div>
        <div class="td td-m audio" @click="playAudio('libro')">li<span class="highlight">br</span>o</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">cl</div>
        <div class="td td-m audio" @click="playAudio('clase')"><span class="highlight">cl</span>ase</div>
        <div class="td td-s disabled">cr</div>
        <div class="td td-m audio" @click="playAudio('escritorio')">es<span class="highlight">cr</span>itorio</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">gl</div>
        <div class="td td-m audio" @click="playAudio('ingle1s')">in<span class="highlight">gl</span>és</div>
        <div class="td td-s disabled">gr</div>
        <div class="td td-m audio" @click="playAudio('negro')">ne<span class="highlight">gr</span>o</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">tl</div>
        <div class="td td-m audio" @click="playAudio('atla1ntico')">a<span class="highlight">tl</span>ántico</div>
        <div class="td td-s disabled">tr</div>
        <div class="td td-m audio" @click="playAudio('tres')"><span class="highlight">tr</span>es</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">fl</div>
        <div class="td td-m audio" @click="playAudio('flor')"><span class="highlight">fl</span>or</div>
        <div class="td td-s disabled">fr</div>
        <div class="td td-m audio" @click="playAudio('fresco')"><span class="highlight">fr</span>esco</div>
      </div>
      <div class="tr">
        <div class="td td-s disabled">dr</div>
        <div class="td td-m audio" @click="playAudio('madre')">ma<span class="highlight">dr</span>e</div>
        <div class="td td-s"></div>
        <div class="td td-m"></div>
      </div>
    </div>
     <div class="title">{{$t('alphabet.es.es_alp_content_13')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_14')}}</div>
    <br>
    <div class="body">{{$t('alphabet.es.es_alp_content_15')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('papa1')">pa<span class="highlight">pá</span></div>
        <div class="td td-l audio" @click="playAudio('me1dico')"><span class="highlight">mé</span>dico</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_16')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('pirata')">pi<span class="highlight">ra</span>ta</div>
        <div class="td td-l audio" @click="playAudio('amigo')">a<span class="highlight">mi</span>go</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_17')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('profesor')">profe<span class="highlight">sor</span></div>
        <div class="td td-l audio" @click="playAudio('espan1ol')">espa<span class="highlight">ñol</span></div>
      </div>
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('joven')"><span class="highlight">jo</span>ven</div>
        <div class="td td-l audio" @click="playAudio('muchos')"><span class="highlight">mu</span>chos</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_18')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('bueno')"><span class="highlight">bue</span>no</div>
        <div class="td td-l audio" @click="playAudio('estudiante')">estu<span class="highlight">dian</span>te</div>
      </div>
    </div>
    <div class="title">{{$t('alphabet.es.es_alp_content_19')}}</div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_20')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_21')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('beso')"><span class="highlight">b</span>eso</div>
        <div class="td td-l audio" @click="playAudio('vino')"><span class="highlight">v</span>ino</div>
      </div>
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('ambos')">am<span class="highlight">b</span>os</div>
        <div class="td td-l audio" @click="playAudio('en vano')">en <span class="highlight">v</span>ano</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_22')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('lobo')">lo<span class="highlight">b</span>o</div>
        <div class="td td-l audio" @click="playAudio('llave')">lla<span class="highlight">v</span>e</div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_23')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_24')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('cama')"><span class="highlight">ca</span>ma</div>
        <div class="td td-m-l audio" @click="playAudio('cosa')"><span class="highlight">co</span>sa</div>
        <div class="td td-m-l audio" @click="playAudio('cuna')"><span class="highlight">cu</span>na</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_25')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('que1')"><span class="highlight">qué</span></div>
        <div class="td td-l audio" @click="playAudio('alquilar')">al<span class="highlight">qui</span>lar</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_26')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('accio1n')">a<span class="highlight">cc</span>ión</div>
        <div class="td td-l audio" @click="playAudio('te1cnico')">té<span class="highlight">cn</span>ico</div>
      </div>
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('contacto')">conta<span class="highlight">ct</span>o</div>
        <div class="td td-l audio" @click="playAudio('ane1cdota')">ané<span class="highlight">cd</span>ota</div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_27')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-m disabled">ch + a = cha</div>
        <div class="td td-xl audio" @click="playAudio('chaqueta')"><span class="highlight">cha</span>queta</div>
      </div>
      <div class="tr">
        <div class="td td-m disabled">ch + e = che</div>
        <div class="td td-xl audio" @click="playAudio('leche')">le<span class="highlight">che</span></div>
      </div>
      <div class="tr">
        <div class="td td-m disabled">ch + i = chi</div>
        <div class="td td-xl audio" @click="playAudio('mochila')">mo<span class="highlight">chi</span>la</div>
      </div>
      <div class="tr">
        <div class="td td-m disabled">ch + o = cho</div>
        <div class="td td-xl audio" @click="playAudio('chocolate')"><span class="highlight">cho</span>colate</div>
      </div>
      <div class="tr">
        <div class="td td-m disabled">ch + u = chu</div>
        <div class="td td-xl audio" @click="playAudio('lechuza')">le<span class="highlight">chu</span>za</div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_28')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_29')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('gato')"><span class="highlight">ga</span>to</div>
        <div class="td td-m-l audio" @click="playAudio('agosto')">a<span class="highlight">go</span>sto</div>
        <div class="td td-m-l audio" @click="playAudio('alguno')">al<span class="highlight">gu</span>no</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_30')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('juguete')">ju<span class="highlight">gue</span>te</div>
        <div class="td td-l audio" @click="playAudio('seguir')">se<span class="highlight">gui</span>r</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_31')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('ligero')">li<span class="highlight">ge</span>ro</div>
        <div class="td td-l audio" @click="playAudio('gimnasio')"><span class="highlight">gi</span>mnasio</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_32')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('cigv1en1a')">ci<span class="highlight">güe</span>ña</div>
        <div class="td td-l audio" @click="playAudio('pingv1ino')">pin<span class="highlight">güi</span>no</div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_33')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_34')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('hombre')"><span class="highlight">h</span>ombre</div>
        <div class="td td-l audio" @click="playAudio('hospital')"><span class="highlight">h</span>ospital</div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_35')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('nin1o')">ni<span class="highlight">ñ</span>o</div>
        <div class="td td-l audio" @click="playAudio('espan1ol')">espa<span class="highlight">ñ</span>ol</div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_36')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_37')}}</div>
    <br>
    <div class="body">{{$t('alphabet.es.es_alp_content_38')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('caro')">ca<span class="highlight">r</span>o</div>
        <div class="td td-l audio" @click="playAudio('tener')">tene<span class="highlight">r</span></div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_39')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('rico')"><span class="highlight">r</span>ico</div>
        <div class="td td-m-l audio" @click="playAudio('alrededor')">al<span class="highlight">r</span>ededor</div>
        <div class="td td-m-l audio" @click="playAudio('perro')">pe<span class="highlight">rr</span>o</div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_40')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_41')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('11')"><span class="highlight">ll</span>ave [MX]</div>
        <div class="td td-m-l audio" @click="playAudio('12')"><span class="highlight">ll</span>evar [MX]</div>
        <div class="td td-m-l audio" @click="playAudio('13')">po<span class="highlight">ll</span>ito [MX]</div>
      </div>
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('1')"><span class="highlight2">ll</span>ave [ES]</div>
        <div class="td td-m-l audio" @click="playAudio('2')"><span class="highlight2">ll</span>evar [ES]</div>
        <div class="td td-m-l audio" @click="playAudio('3')">po<span class="highlight2">ll</span>ito [ES]</div>
      </div>
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('14')"><span class="highlight">ll</span>orar [MX]</div>
        <div class="td td-m-l audio" @click="playAudio('15')"><span class="highlight">ll</span>uvia [MX]</div>
        <div class="td td-m-l"></div>
      </div>
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('4')"><span class="highlight2">ll</span>orar [ES]</div>
        <div class="td td-m-l audio" @click="playAudio('5')"><span class="highlight2">ll</span>uvia [ES]</div>
        <div class="td td-m-l"></div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_42')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-s-l disabled">j + a = ja</div>
        <div class="td td-s-l audio" @click="playAudio('roja')">ro<span class="highlight">ja</span></div>
        <div class="td td-s-l disabled">j + o = jo</div>
        <div class="td td-s-l audio" @click="playAudio('conejo')">cone<span class="highlight">jo</span></div>
      </div>
      <div class="tr">
        <div class="td td-s-l disabled">j + e = je</div>
        <div class="td td-s-l audio" @click="playAudio('jefe')"><span class="highlight">je</span>fe</div>
        <div class="td td-s-l disabled">j + u = ju</div>
        <div class="td td-s-l audio" @click="playAudio('jugar')"><span class="highlight">ju</span>gar</div>
      </div>
      <div class="tr">
        <div class="td td-s-l disabled">j + i = ji</div>
        <div class="td td-s-l audio" @click="playAudio('jirafa')"><span class="highlight">ji</span>rafa</div>
        <div class="td td-s-l"></div>
        <div class="td td-s-l"></div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_43')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_44')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('xilo1fono')"><span class="highlight">x</span>ilófono</div>
        <div class="td td-l audio" @click="playAudio('xeno1fobo')"><span class="highlight">x</span>enófobo</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_45')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('examen')"><span class="highlight">exa</span>men</div>
        <div class="td td-l audio" @click="playAudio('e1xito')"><span class="highlight">éxi</span>to</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_46')}}</div>
    <br>
    <div class="body">{{$t('alphabet.es.es_alp_content_47')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('texto')">te<span class="highlight">xt</span>o</div>
        <div class="td td-l audio" @click="playAudio('explicar')">e<span class="highlight">xp</span>licar</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_48')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('me1xico')">Mé<span class="highlight">xi</span>co</div>
        <div class="td td-l audio" @click="playAudio('mexicano')">me<span class="highlight">xi</span>cano</div>
      </div>
    </div>
    <div class="subtitle">{{$t('alphabet.es.es_alp_content_49')}}</div>
    <div class="body">{{$t('alphabet.es.es_alp_content_50')}}</div>
    <br>
    <div class="body">{{$t('alphabet.es.es_alp_content_51')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('16')">man<span class="highlight">za</span>na [MX]</div>
        <div class="td td-m-l audio" @click="playAudio('17')">mar<span class="highlight">zo</span> [MX]</div>
        <div class="td td-m-l audio" @click="playAudio('18')"><span class="highlight">zu</span>mo [MX]</div>
      </div>
      <div class="tr">
        <div class="td td-m-l audio" @click="playAudio('6')">man<span class="highlight2">za</span>na [ES]</div>
        <div class="td td-m-l audio" @click="playAudio('7')">mar<span class="highlight2">zo</span> [ES]</div>
        <div class="td td-m-l audio" @click="playAudio('8')"><span class="highlight2">zu</span>mo [ES]</div>
      </div>
    </div>
    <div class="body">{{$t('alphabet.es.es_alp_content_52')}}</div>
    <br>
    <div class="body">{{$t('alphabet.es.es_alp_content_53')}}</div>
    <div class="table">
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('19')">prin<span class="highlight">ce</span>sa [MX]</div>
        <div class="td td-l audio" @click="playAudio('20')">prín<span class="highlight">ci</span>pe [MX]</div>
      </div>
      <div class="tr">
        <div class="td td-l audio" @click="playAudio('9')">prin<span class="highlight2">ce</span>sa [ES]</div>
        <div class="td td-l audio" @click="playAudio('10')">prín<span class="highlight2">ci</span>pe [ES]</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      playAudio(filename) {
        this.$emit('playAudio', filename)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/courses/alphabet.scss';
</style>